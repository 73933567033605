import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Button,
} from '@chakra-ui/react';
import * as React from 'react';

export const UnexpectedError: React.FC<AlertProps & { message?: string; onRetry?: () => void }> = ({
  message,
  onRetry,
  ...props
}) => {
  return (
    <Alert status="error" {...props}>
      <AlertIcon />
      <AlertTitle>Unexpected Error</AlertTitle>
      <AlertDescription>
        {message ?? 'We are working on a fix'}
        {onRetry && (
          <Button ml={2} onClick={onRetry} size="sm" variant="link">
            Retry
          </Button>
        )}
      </AlertDescription>
    </Alert>
  );
};
