import { gql, useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import * as React from 'react';

import { EUserAccountStatus } from '@/client/legacy-shared-modules/user/userEnums';
import { OnboardingFlowState } from '@/graphql-types/globalTypes';
import { logError } from '@/imports/logging/ClientLogger';

import {
  ActivationModalManager_ChildScreenQueries,
  ActivationModalManager_ChildScreenQueriesVariables,
} from './graphql-types/ActivationModalManager_ChildScreenQueries';
import { ActivationModalManager_Skip } from './graphql-types/ActivationModalManager_Skip';
import {
  ActivationModalProvider_CurrentUser,
  ActivationModalProvider_CurrentUser_currentUser_onboardingFlowState,
} from './graphql-types/ActivationModalProvider_CurrentUser';

import ActivationModal from './ActivationModal';
import { useActivationModal } from './useActivationModal';

const CHILD_SCREEN_QUERIES = gql`
  query ActivationModalManager_ChildScreenQueries(
    $groupsInput: GroupsInput!
    $followedTagsInput: TagsFollowedByUserInput!
    $allTagsInput: TagsNotFollowedByUserInput!
  ) {
    currentUser {
      _id
      ...ActivationModal_CurrentUserFragment
    }
    groups(input: $groupsInput) {
      results {
        _id
        ...ActivationModal_AllGroupsFragment
      }
    }
    tagsFollowedByUser(input: $followedTagsInput) {
      tags {
        _id
        ...ActivationModal_FollowedTagsFragment
      }
    }
    tagsNotFollowedByUser(input: $allTagsInput) {
      tags {
        _id
        ...ActivationModal_AllTagsFragment
      }
    }
    tenant {
      result {
        _id
        ...ActivationModal_TenantFragment
      }
    }
    sponsorsForOnboardingModal {
      results {
        _id
        ...ActivationModal_SponsorsFragment
      }
    }
  }
  ${ActivationModal.currentUserFragment}
  ${ActivationModal.allGroupsFragment}
  ${ActivationModal.followedTagsFragment}
  ${ActivationModal.allTagsFragment}
  ${ActivationModal.tenantFragment}
  ${ActivationModal.allSponsorsFragment}
`;

const SKIP = gql`
  mutation ActivationModalManager_Skip {
    skipOnboardingFlow {
      user {
        _id
      }
    }
  }
`;

const CURRENT_USER = gql`
  query ActivationModalProvider_CurrentUser {
    currentUser {
      _id
      accountStatus
      onboardingFlowState {
        isSkippedOrCompleted
        currentFlowState {
          currentStateIndex
          validOnboardingFlowStates
        }
      }
    }
  }
`;

type ActivationModalContext =
  | {
      onboardingFlowState: ActivationModalProvider_CurrentUser_currentUser_onboardingFlowState;
    }
  | undefined;

const Context = React.createContext<ActivationModalContext>(undefined);

export const useActivationModalContext = (): ActivationModalContext =>
  React.useContext(Context);

export const ActivationModalManager: React.FC = () => {
  const { ready, showModal } = useActivationModal();
  const router = useRouter();

  const [skipFlowFunc, { loading: skipLoading, called: skipCalled }] =
    useMutation<ActivationModalManager_Skip>(SKIP);

  const {
    data: currentUser,
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery<ActivationModalProvider_CurrentUser>(CURRENT_USER, {
    skip: !ready || !showModal,
  });
  const {
    data: childScreenData,
    loading: childScreenLoading,
    error: childScreenError,
  } = useQuery<
    ActivationModalManager_ChildScreenQueries,
    ActivationModalManager_ChildScreenQueriesVariables
  >(CHILD_SCREEN_QUERIES, {
    variables: {
      groupsInput: {
        offset: 0,
        limit: 50,
      },
      followedTagsInput: {
        offset: 0,
        limit: 50,
      },
      allTagsInput: {
        offset: 0,
        limit: 50,
      },
    },
    skip: !ready || !showModal,
  });

  if (currentUserError) {
    throw new Error(currentUserError.message);
  }
  if (childScreenError) {
    throw new Error(childScreenError.message);
  }

  if (
    !currentUser?.currentUser?.onboardingFlowState ||
    !currentUser?.currentUser?.onboardingFlowState?.currentFlowState
      ?.validOnboardingFlowStates ||
    !childScreenData?.currentUser ||
    !childScreenData?.groups?.results ||
    !childScreenData?.tagsFollowedByUser?.tags ||
    !childScreenData?.tagsNotFollowedByUser?.tags ||
    !childScreenData?.tenant?.result
  ) {
    return null;
  }

  if (
    currentUserLoading ||
    childScreenLoading ||
    skipLoading ||
    skipCalled ||
    !ready ||
    !showModal
  ) {
    return null;
  }

  const { accountStatus, onboardingFlowState } = currentUser.currentUser;

  const { currentStateIndex, validOnboardingFlowStates } =
    currentUser.currentUser.onboardingFlowState?.currentFlowState;

  /* determine if this screen is dismissable/modal is closable */
  const isModalIsClosable = (
    currentFlowStep: OnboardingFlowState | undefined,
    isInactiveUser: boolean
  ): boolean => {
    const CLOSE_MODAL_DISABLED_STEPS = [
      OnboardingFlowState.ACCEPT_CODE_OF_CONDUCT,
      OnboardingFlowState.ACCEPT_PRIVACY_POLICY,
      OnboardingFlowState.ACCEPT_TERMS_AND_CONDITIONS,
      OnboardingFlowState.CONFIRM_DETAILS,
      OnboardingFlowState.DOWNLOAD_APP,
      OnboardingFlowState.FOLLOW_SPONSORS,
      OnboardingFlowState.JOIN_GROUP,
      OnboardingFlowState.JOIN_TAGS
    ];
    // This is the ActivateAccount or CreatePassword screens
    if (isInactiveUser) {
      return false;
    }
    /* otherwise, check the current step against the list of steps
       where close is disabled */
    return currentFlowStep
      ? !CLOSE_MODAL_DISABLED_STEPS.includes(currentFlowStep)
      : true;
  };
  const isInactiveUser = accountStatus !== EUserAccountStatus.ACTIVE;
  const currentFlowStep = validOnboardingFlowStates[currentStateIndex];
  const isCloseModalDisabled = !isModalIsClosable(
    currentFlowStep,
    isInactiveUser
  );

  const isFirstStepOfFlow =
    currentStateIndex === 0 && validOnboardingFlowStates.length > 0;

  const noValidOnboardingSteps =
    currentUser?.currentUser?.onboardingFlowState?.currentFlowState
      ?.currentStateIndex === 0 &&
    currentUser?.currentUser?.onboardingFlowState?.currentFlowState
      .validOnboardingFlowStates.length === 0;

  const shouldSkipOnboardingSteps =
    noValidOnboardingSteps && !skipCalled && !isInactiveUser;

  if (shouldSkipOnboardingSteps) {
    skipFlowFunc().catch((err) => logError(err));
    return null;
  }

  return (
    <Context.Provider
      value={{
        onboardingFlowState,
      }}
    >
      <ActivationModal
        /* disable closing of the modal on the Accecpt Code Of Conduct
        Step in a simple way here. Someday maybe the child screens should
        be able to control this requirement. */
        isCloseDisabled={isCloseModalDisabled}
        isFirstStepOfFlow={isFirstStepOfFlow}
        currentUser={childScreenData.currentUser}
        allGroups={childScreenData.groups.results || []}
        followedTags={childScreenData.tagsFollowedByUser.tags || []}
        allTags={childScreenData.tagsNotFollowedByUser.tags || []}
        tenant={childScreenData.tenant.result}
        allSponsors={childScreenData.sponsorsForOnboardingModal.results}
      />
    </Context.Provider>
  );
};
