import { MutationTuple, gql, useMutation } from '@apollo/client';

import {
  useAcceptPrivacyPolicy_AcceptPrivacyPolicy,
  useAcceptPrivacyPolicy_AcceptPrivacyPolicyVariables,
} from './graphql-types/useAcceptPrivacyPolicy_AcceptPrivacyPolicy';

const ACCEPT_PRIVACY_POLICY = gql`
  mutation useAcceptPrivacyPolicy_AcceptPrivacyPolicy(
    $input: AcceptPrivacyPolicyInput!
  ) {
    acceptPrivacyPolicy(input: $input) {
      user {
        _id
        acceptedPrivacyPolicy
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
    }
  }
`;

export const useAcceptPrivacyPolicy = (
  isPartOfOnboardingFlow: boolean
): MutationTuple<useAcceptPrivacyPolicy_AcceptPrivacyPolicy, {}> => {
  const result = useMutation<
  useAcceptPrivacyPolicy_AcceptPrivacyPolicy,
  useAcceptPrivacyPolicy_AcceptPrivacyPolicyVariables
  >(ACCEPT_PRIVACY_POLICY, {
    variables: { input: { isPartOfOnboardingFlow } },
  });
  return result;
};
