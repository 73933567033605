import { MutationTuple, gql, useMutation } from '@apollo/client';

import {
  useAcceptTermsAndConditions_AcceptTermsAndConditions,
  useAcceptTermsAndConditions_AcceptTermsAndConditionsVariables,
} from './graphql-types/useAcceptTermsAndConditions_AcceptTermsAndConditions';

const ACCEPT_TERMS_AND_CONDITIONS = gql`
  mutation useAcceptTermsAndConditions_AcceptTermsAndConditions(
    $input: AcceptTermsAndConditionsInput!
  ) {
    acceptTermsAndConditions(input: $input) {
      user {
        _id
        acceptedTermsAndConditions
        onboardingFlowState {
          isSkippedOrCompleted
          currentFlowState {
            currentStateIndex
            validOnboardingFlowStates
          }
        }
      }
    }
  }
`;

export const useAcceptTermsAndConditions = (
  isPartOfOnboardingFlow: boolean
): MutationTuple<useAcceptTermsAndConditions_AcceptTermsAndConditions, {}> => {
  const result = useMutation<
  useAcceptTermsAndConditions_AcceptTermsAndConditions,
  useAcceptTermsAndConditions_AcceptTermsAndConditionsVariables
  >(ACCEPT_TERMS_AND_CONDITIONS, {
    variables: { input: { isPartOfOnboardingFlow } },
  });
  return result;
};
