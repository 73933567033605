import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import {
  ChakraProvider,
  theme as baseTheme,
  extendTheme,
} from '@chakra-ui/react';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Head from 'next/head';
import * as React from 'react';
import 'stream-chat-react/dist/css/v2/index.css';

import { apolloClient } from '@/client/startup/apollo-client';
import { ErrorPage } from '@/components/ErrorPage';
import { NoTenantFound } from '@/components/NoTenantFound';
import { theme } from '@/imports/ui/chakra/theme';
import { TENANT } from '@/queries';
import { Tenant } from '@/queries/graphql-types/Tenant';
import { Tradewing } from '@/tradewing';

type CustomAppProps = {
  data: Tenant | null;
  error: Error | null;
};

const CustomApp = ({
  Component,
  pageProps,
  data,
  error,
}: AppProps & CustomAppProps) => {
  if (error) {
    return (
      <ChakraProvider
        theme={extendTheme({
          ...theme,
          colors: { primary: baseTheme.colors.blue },
        })}
      >
        <ErrorPage />
      </ChakraProvider>
    );
  }

  if (!data?.tenant.result) {
    return (
      <ChakraProvider
        theme={extendTheme({
          ...theme,
          colors: { primary: baseTheme.colors.blue },
        })}
      >
        <NoTenantFound />
      </ChakraProvider>
    );
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        <title>
          {data.tenant.result.acronym || data.tenant.result.displayName}
        </title>
        <link
          rel="shortcut icon"
          href={data.tenant.result.logoIcon?.shortcutIcon || undefined}
        />
        <link
          rel="preload"
          href="/fonts/44393f83cfe6e236d297.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <style type="text/css">
          {`
        @font-face {
          font-family: "icomoon";
          src: url("/fonts/44393f83cfe6e236d297.ttf") format("truetype");
        }
      `}
        </style>
        <link
          rel="apple-touch-icon"
          href={data.tenant.result.logoIcon?.appleTouchIcon || undefined}
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <Tradewing primaryColor={data.tenant.result.primaryColor}>
          <Component {...pageProps} />
        </Tradewing>
      </ApolloProvider>
    </>
  );
};

const globalCache = new InMemoryCache();

CustomApp.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps & CustomAppProps> => {
  try {
    let uri;
    let cache;

    if (typeof window !== 'undefined') {
      uri = '/graphql2';
      cache = globalCache;
    } else {
      uri = process.env.NEXT_PUBLIC_APOLLO_HTTP_URI;
      cache = new InMemoryCache();
    }

    const client = new ApolloClient({
      ssrMode: typeof window === 'undefined',
      uri,
      cache,
    });

    let tenant_domain;

    if (typeof window !== 'undefined') {
      tenant_domain = window.location.hostname;
    } else {
      tenant_domain = appContext.ctx.req?.headers.tenant_domain;
    }
    const { data } = await client.query<Tenant>({
      query: TENANT,
      context: { headers: { tenant_domain } },
      fetchPolicy: 'no-cache',
    });

    return {
      ...(await App.getInitialProps(appContext)),
      data,
      error: null,
    };
  } catch (error) {
    if (error?.networkError?.statusCode === 422) {
      if (appContext.ctx.res) {
        appContext.ctx.res.statusCode = 404;
      }

      return {
        ...(await App.getInitialProps(appContext)),
        data: null,
        error: null,
      };
    }

    if (appContext.ctx.res) {
      appContext.ctx.res.statusCode = 500;
    }

    return {
      ...(await App.getInitialProps(appContext)),
      data: null,
      error,
    };
  }
};

export default CustomApp;
