import { DocumentNode, gql } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { Redraft } from '@/components/Redraft';
import { logError } from '@/imports/logging/ClientLogger';
import { Prose } from '@/imports/ui/chakra/components/Prose';

import { TermsAndConditionsStep_TenantFragment } from './graphql-types/TermsAndConditionsStep_TenantFragment';

import { ActivationModalBody } from '../ActivationModalBody';
import { ActivationModalFooter } from '../ActivationModalFooter';
import { ActivationModalHeader } from '../ActivationModalHeader';
import { useAcceptTermsAndConditions } from './useAcceptTermsAndConditions';

const TENANT_FRAGMENT = gql`
  fragment TermsAndConditionsStep_TenantFragment on Tenant {
    _id
    termsAndConditions
  }
`;

export const TermsAndConditionsStep: React.FC<{
  tenant?: TermsAndConditionsStep_TenantFragment;
}> & {
  tenantFragment: DocumentNode;
} = (props) => {
  const toast = useToast();
  const [accepted, setAccepted] = React.useState(false);
  const [submitAcceptTermsAndConditions, submitAcceptTermsAndConditionsResult] =
    useAcceptTermsAndConditions(true);

  const checkboxText = useBreakpointValue({
    base: 'I read & accept this code',
    md: 'I read & accept this terms and conditions',
  });

  const { handleSubmit } = useForm();

  if (!props.tenant) {
    return null;
  }

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      overflow="auto"
      flexGrow="1"
      noValidate
      onSubmit={handleSubmit(async () => {
        try {
          await submitAcceptTermsAndConditions();
        } catch (err) {
          logError(err);
          toast({
            title:
              'Failed to accept the terms and conditions, please refresh and try again.',
            status: 'error',
          });
        }
      })}
    >
      <ActivationModalHeader
        title="please read & accept our terms and conditions."
        subtitle="The privacy policy ensures that we cultivate a community we all enjoy and find useful!"
      />
      <ActivationModalBody>
        <Box bg="gray.50" pt="1" pr="4" pl="4" pb="4" borderRadius="base">
          {props.tenant.termsAndConditions && (
            <Prose>
              <Redraft rawDraftContentState={props.tenant.termsAndConditions} />
            </Prose>
          )}
        </Box>
      </ActivationModalBody>
      <ActivationModalFooter>
        <Checkbox
          isChecked={accepted}
          onChange={() => setAccepted((oldValue) => !oldValue)}
        >
          {checkboxText}
        </Checkbox>
        <Button
          type="submit"
          isDisabled={!accepted}
          isLoading={submitAcceptTermsAndConditionsResult.loading}
        >
          Accept
        </Button>
      </ActivationModalFooter>
    </Box>
  );
};

TermsAndConditionsStep.tenantFragment = TENANT_FRAGMENT;
